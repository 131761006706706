


.property {
  position: relative;
  /* Add any other styles you need for the property container */
}

.swiper-button-next,
.swiper-button-prev {
  color: white; /* Change arrow color */
  background-color: rgba(255, 255, 255, 0.5); /* Add background */
  border-radius: 50%; /* Make it circular */
  width: 40px; /* Adjust size */
  height: 40px;
  opacity: 0.5; /* Make it visible */
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 20px; /* Adjust arrow icon size */
}


.prev-button {
  position: absolute;
  display: flex;
  justify-content: center ;
  align-items: center ;
  padding-bottom: 7px;
  top: 50% !important;
  left: 0;
  transform: translateX(50%);
  background-color: rgba(93, 88, 88, 0.904);
  border: none;
  color: #fff;
  font-size: 24px;
  width:30px;
  height:30px;
  border-radius: 50%;
  cursor: pointer;
}


.close-button{
  position: absolute;
  display: flex;
  justify-content: center ;
  align-items: center ;
  top: 20px !important;
  padding-bottom: 5px;
  right: 0;
  transform: translateX(-100%);
  background-color: rgba(93, 88, 88, 0.904);
  border: none;
  color: #fff;
  font-size: 16px;
  width:20px;
  height:20px;
  border-radius: 50%;
  cursor: pointer;
}

.next-button {
  position: absolute;
  display: flex;
  justify-content: center ;
  padding-bottom: 7px;
  align-items: center ;
  background-color: rgba(93, 88, 88, 0.904);
  top: 48.5% !important;
  border-radius: 50%;
  right: 0;
  width:30px;
  height:30px;
  transform: translateX(-50%);
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
}

.gallery-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.showMore{
  position: relative;
  top:-50px;
  left:-130px;
  background-color: #149444 !important;
  border: none !important;
}

.gallery {
  /* position: relative; */
  display: flex;
  /* overflow: hidden; */
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* max-width: 80vw;
  max-height: 90vh; */
  height: fit-content;
  margin-inline: 80px;
  
}

.gallery img {
  width: 80%;
  height: auto;
  margin: 5px;
  object-fit: contain;
}
/* .gallery .image_gallery {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100vw;
  height: 100vh;
} */






#main-image {
 
  height: 400px;
  width:100%;
  object-fit: cover;
  
}
.hours-rules{
  margin-left: 20px;
  width: 100%;
}

.vl {
  height: 400px;
}

.search-sec-ctr{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}


#sub-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.small-image {
  object-fit: cover;
  margin-top: 5px;
  width: 100%;
  height:95px;
  margin-bottom: 2px;
}
.price {
  padding-right: 5px;
}
.avg {
  line-height: 2.3rem;

  font-size: 13px;
}
.book-img {
  width: 18px;
}
.vl {
  border-left: 2px solid #07344879;
  height: 400px;
  position: absolute;
  left: -1rem;
  top: 0;
}
#ver-line {
  position: absolute;
  width: 53vh;
  top: 11rem;
  right: -8rem;
  padding: 0.6px;
  background-color: #07344829;

  -webkit-transform: rotate(90deg);
}
#ver-line-small {
  position: absolute;
  width: 7vh;
  top: 1rem;
  right: 2rem;

  background-color: #073448c1;

  -webkit-transform: rotate(90deg);
}
.icon-img {
  width: 60px;
  height: 50px;
}
.clickAble,
.clickAble:hover {
  cursor: pointer;
  color: #073448;
  text-decoration: none;
  white-space: nowrap;
}






#map-det {
  margin-bottom: 40px;
  margin-top: 40px;
  height: 490px;
  width: 550px;
}
.book {
  background-color: #073448;
  border: none;
  border-radius: 5px;
  width: 100%;
  height: 45px;
  font-size: 14px;
  color: #fff;
  font-family: "lexMed";
  font-weight: lighter;
}
.available {
  background-color: #149444;
  border: none;
  border-radius: 5px;
  width: 100%;
  height: 45px;
  color: #fff;
  font-size: 14px;
  font-weight: lighter;
  font-family: "lexMed";
}
#icon {
  width: 55px;
}
.bed-info {
  padding-top: 8%;
  padding-left: 10%;
  width: 450px;
  height: 150px;
  border: 0.5px solid #0734487c;
}
.bold-ed{
  font-weight:  bold;
}
@media (max-width: 1600px) {
 

  #map-det {
    width: 400px;
    height: 300px;
  }
  
 
  
 
}
@media (max-width: 1300px) {
 
 

  #map-det {
    width: 400px;
    height: 300px;
  }
 
 
  
 
  #ver-line-small {
    display: none;
  }
}
@media (max-width: 1200px) {
  #map-det {
    width: 400px;
    height: 300px;
  }
 
 
  

  #ver-line-small {
    display: none;
  }





}
@media ((min-width: 991px) and (max-width:1000px)) {
  #map-det {
    width: 400px;
    height: 300px;
  }
  #ver-line-small {
    display: none;
  }
  
  

}
@media (max-width: 990px) {

  .mob-row2{

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
      }
    
     

  
  
  
  #ver-line-small {
    display: none;
  }
  .vl {
    display: none;
  }

  
  

}
@media (max-width: 768px) {
  .icon-img {
    width: 30px;
    height: 30px;
  }
  .mob-row2{

display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
flex-wrap: wrap;
  }

 #map-det {
  width: 100% !important;
 }

  #ver-line-small {
    display: none;
  }
  .vl {
    display: none;
  }

  .bed-info {
    width: 100% !important;
    height: auto !important;
    padding-block: 8% !important;
  }
  

}
@media (max-width: 600px) {
  .check-calender {
    width:90% !important
  }
  .rmdp-wrapper, .rmdp-calendar, .rmdp-day-picker div{
width:100% !important
  }
  .bed-info{
    width:300px
  }
  .sec-row{
   /* padding-left: 1rem; */
  
  }

  .top-destinations-txt{
    font-size: larger;
    color: #032736;
  }

  .mob-row2{
    display: flex;
    justify-content: center;
    align-items: center;
  }




 
 

  
  .vl {
    display: none;
  }
  #ver-line-small {
    display: none;
  }
  .ver-line-small {
    display: none;
  }

  #map-det {
    width: 100% !important;
    height: 200px;
  }
 
}
@media(max-width:992px)
{  .active-det {
  display: none;
}


.flexed{
  text-align: center;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexed.col-sm-12.col-md-12.col-lg-3.col-xl-2.position-relative {
  margin-left: 50px;
}






.mob-pic{
  margin-left: 25px;
}





.about-this-rental{
  margin-left:"80px";
  margin-bottom:"40px";
}

.mob-row4{
  margin-left: 60px;
}

}


@media (min-width:1600px){
 
 



     #map-det {
      width: 400px;
      height: 300px;
    }
   
   

}





@media (min-width:1800px){

  .mob-row2{
    margin-left: 0px !important;
   }

   .mob-row3{
    margin-left: 0px !important;
   }

 

   
  





.buttons-ctr{
  left:100px;
}
}



@media (min-width:1200px){
  

     .mob-row2{
      margin-left: -50px;
     }

     .mob-row3{
      margin-left: -60px;
     }
    
   
    
    
   
    
    
    .buttons-ctr{
      left:50px;
    }
}

.absolute{
  position: absolute;
}
.relative {
  position: relative;
}

.buttons-ctr{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  justify-content: center;
}