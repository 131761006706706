

.logo-nav-container{
  
  height:150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.navbar-brand{
  z-index: 1;
}

.nav-search-btn-min {
  display: none;
}

.logo-nav-container-desk{
  position: absolute;
  width: 100%;
display: flex;
justify-content: center !important;
align-items: center;
top:-5px;
}

.logo{
  position: relative !important; 


}


.mobile-form-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  background-color: white;
  box-shadow: 6px 6px 30px 2px rgb(0, 0, 0, 0.1);
  max-width:90%;
 margin: auto ;
 height: 330px;
  
  border-radius: 20px;
  padding:10px;
  margin-top: -100px;
}

.mobile-search-button{
  width:100%;
  height:50px;
  border-radius: 10px;
  background-color: #073448;
  color: #fff;
  font-size: 22px;
}


.mobile-chalets-header{
  font-size: 28px;
  font-weight: 700;
  padding: 40px;
  padding-top: 100px;
  color: #073448;
}

.mobile-input{
 border-radius: 5px;
  border: none;
  margin-left: 7px;
width:80%;
background: none !important;
outline: none;
color: black !important;
}



.mobile-input:focus{
  
   border: 1px solid black;
   outline: none;
 }

.error-mobile{
  color: red;
  position: relative;
  top:-50px;
  font-size: 20px;
  text-align: center;
  
  
}



.check1{
  width:48%;
}
.check2{
  width:48%;
}

.check1>input{
  width:90%;
}
.check2>input{
  width:90%;
}

.labelInputContainer{
  border:1px solid #073448;
  border-radius:10px;
  padding: 5px;
  
}

.labelInputContainer>label{
  
 margin-left: 10px;
 font-weight: 600;
 font-size: 14px;
 position: relative;
 top:5px;
 font-weight: 700;
  
}


.checks-container{
display: flex;
justify-content: space-between;
align-items:normal;
}




.mobile-img{
  background-image: url("../../../images/shutterstock_245455543.jpg");
  background-repeat: no-repeat;
  object-fit:cover;

  
  background-size: auto 100%;
  background-position: 60%;
  
  height: 400px;
  
  
  
 
  /*color: #073448;*/
 

}





.navbar{
  position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    
}






.navbar a {
  margin-bottom: 3%;
  line-height: 4rem;
  font-family: "lexReg";
  font-weight: bolder;
}



.search-input {
  display: block;
 
  width: 100%;
  /* height: calc(1.5em + 0.75rem + 2px); */
  padding: 0rem 0.7rem 0.375rem 0.05rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000000;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.css-1u9des2-indicatorSeparator {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  width: 1px;
  background-color: transparent !important;
  margin-bottom: 8px;
  margin-top: 8px;
  box-sizing: border-box;
}
.error{
  color:red;
  position:absolute;
  top:60px;
  left:45%;
  font-size: 20px;
}



nav {
  position: relative;
  background-color: #ffffff;
  height: 70px;
  width: 100%;
}
.bolder {
  font-family: "lexReg";
  font-weight: bolder;
}
.logo {
  position: absolute;

 margin: auto !important;
  display: block;
}
.nav-search-container {
  width: 100%;
}

.chalets-header-ctr{
padding-right: 20% !important;
}



.bac-sec {
  background-image: url("../../../images/shutterstock_245455543.jpg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position:5%;
  height: 200px;
  width: 100%;
  padding-top:220px;
 
  font-size: 100%;
  color: #073448;
  font-family: "lexSemi2";
  font-weight: bolder;
}
.search {
  position: relative;
}

.chalets-header{
  
  position: relative;
  
  letter-spacing: 0.6px;

  
}

.search-row {
  position: relative;
  box-shadow: 6px 6px 30px 2px rgb(0, 0, 0, 0.1);
  position: absolute;
  top: -2rem;
  margin: 0 !important;
  background-color: #ffffff;
  padding: 1% 1% 0% 1%;

  
  border-radius: 20px 1px 1px 20px;
}
.navbar-mobile {
  position: absolute;
  overflow: hidden;
  top: 0%;
  right: 0% !important;
  box-shadow: 6px 6px 30px 10px rgb(0, 0, 0, 0.4);
  bottom: 0;
  background-color: #f5efef;
  transition: 0.3s;
  z-index: 999;
  width: 300px;
  height: 450px;
  padding: 2rem;
  border-radius: 4px;
}
.bi-x {
  color: #000000;
  cursor: pointer;
  text-align: right;
}


.moblie-btn {
  border: none;
  border-radius: 4px;
  background-color: #073448;
  color: #ffffff;
  font-size: 15px;
}
#hr {
  position: absolute;
  width: 60px;
  top: 1rem;
  left: -2rem;
  padding: 0.6px;
  background-color: #07344829;

  -webkit-transform: rotate(90deg);
}
.nav-search-btn,
.nav-search-btn:hover,
.nav-search-btn:focus,
.nav-search-btn:active {
  position: absolute;
  top: -0.8rem;
  font-family: "lexReg";
  font-weight: 500;
  border: none;
  background-color: #073448;
  color: #ffffff;
  width: 100%;
  height: 122%;
  margin-top: -2px;
  font-size: 20px;
}
@media (max-width: 2560px) {
  .nav-search-container {
    margin-bottom:80px;
  }
  .bac-sec {
    height: 540px;
  }
  .bac-sec h1 {
    font-size: 45px;
  }
  .navbar a {
    font-size: 19px;
  }
  .logo {
    top: 1rem;
    margin: auto !important;

    width: 120px;
  }



}



@media (min-width: 1800px){
.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1400px !important;
}



.bac-sec h1 {
  font-size: 50px;
 
}



}




@media (max-width: 2000px) {
  .nav-search-container {
    margin-bottom:80px;
  }
  .bac-sec {
    height: 650px;
    margin-left: 0px !important;
  }
  .bac-sec h1 {
    font-size: 50px;
  }
}
@media (max-width: 1900px) {
  .bac-sec {
    height: 650px;
    padding-top: 12%;
    margin-left: 0px !important;
  }
  .bac-sec h1 {
    font-size: 50px;
  }
}
@media (max-width: 1800px) {
  .nav-search-container {
    margin-bottom:80px;
  }
  .bac-sec {
    height: 600px;
    margin-left: 0px !important;
  }
  .bac-sec h1 {
    font-size: 50px;
  }
}
@media (max-width: 1700px) {
  .nav-search-container {
    margin-bottom:80px;
  }
  .bac-sec {
    height: 540px;
    margin-left: 0px !important;
  }
  .bac-sec h1 {
    font-size: 45px;
  }
}
@media (max-width: 1600px) {
  .nav-search-container {
    margin-bottom:80px;
  }
  .bac-sec {
    height: 540px;
    margin-left: 0px !important;
  }
  .navbar a {
    font-size: 17px;
  }
  .logo {
    top: 1.2rem;
    margin: auto !important;

    width: 110px;
  }
  .bac-sec h1 {
    font-size: 42px;
  }
}
@media (max-width: 1400px) {
  .nav-search-container {
    margin-bottom:80px;
  }
  .bac-sec {
    height: 500px;
    margin-left: 0px !important;
  }
  .navbar a {
    font-size: 16px;
  }
  .logo {
    top: 1.2rem;
    margin: auto !important;

    width: 110px;
  }
  /* nav{
        height: 12vh;
    } */
  /* .bac-sec{ height: 50vh;} */
  .bac-sec h1 {
    font-size: 40px;
    
  }
}
@media (max-width: 1300px) {
  .nav-search-container {
    margin-bottom:80px;
  }
  .bac-sec {
    height: 450px;
    margin-left: 0px !important;
  }
  .navbar a {
    font-size: 16px;
  }
  .logo {
    top: 1.2rem;
    margin: auto !important;

    width: 110px;
  }
  /* nav{
        height: 11vh;
    } */
  /* .search-row{
    
        position:absolute;
        top: -2rem;
        left: 0rem;
        background-color: #ffffff;
        padding: 1% 1% 0% 1%;
    
      width: 100%;
        border-radius: 20px 1px 1px 20px;
  
    
    } */
  /* .bac-sec{ height: 50vh;} */
  .bac-sec h1 {
    font-size: 35px;
  }
  /* nav{
        height: 10vh;
    } */
}

@media (min-width: 1800px){
.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1380px !important;
}

.search-mr{
  max-width: 1400px;
    margin-left: 15vw;
}


}

@media (max-width: 1200px) {
  .nav-search-container {
    margin-bottom: 70px;
  }
  .navbar a {
    font-size: 15px;
  }
  .logo {
    top: 1.5rem;
    margin: auto !important;

    width: 100px;
  }
  .bac-sec {
    height: 400px;
    margin-left: 0px !important;
  }
  /* .search-row{
    
        position:absolute;
        top: -2rem;
        left: 5rem;
        background-color: #ffffff;
        padding: 1% 1% 0% 1%;
    
      width: 80%;
        border-radius: 20px 1px 1px 20px;
  
    
    } */

  .bac-sec h1 {
    font-size: 30px;
  }
  /* nav{
        height: 10vh;
    }
    .logo{
       
        width: 10%;
    }
    .navbar a{
        font-size: 80%;
    } */
}

@media (max-width: 1000px) {
  #hr {
    position: absolute;
    width: 40px;
    top: 0.2rem;
    left: -3rem;
    padding: 0.6px;
    background-color: #07344829;

    -webkit-transform: rotate(90deg);
  }
  .nav-search-btn,
  .nav-search-btn:hover,
  .nav-search-btn:focus,
  .nav-search-btn:active {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    border: none;
    background-color: #073448;
    color: #ffffff;
    width: 112%;
    height: 118%;
    font-size: 15px;
  }
  .search-row h6 {
    font-size: 10px;
  }
  .search-row p {
    font-size: 10px;
  }
  .bac-sec {
    margin-left: 0px !important;
    height: 300px;
  }
  .bac-sec h1 {
    font-size: 23px;
  }
  /* nav{
        height: 12vh;
    }
    .logo{
       
        width: 15%;
    } */
  /* .search-row{
    
        position:absolute;
        top: -1rem;
        left: 5rem;
        background-color: #ffffff;
        padding: 1% 1% 0% 1%;
    
      width: 80%;
        border-radius: 20px 1px 1px 20px;
  
    
    } */
  .logo {
    position: absolute;
    top: 1rem;
    margin: auto !important;
    display: block;
    width: 15%;
  }
  .navbar a {
    font-size: 80%;
  }
}

@media (max-width: 767px) {
  .labelInputContainer{
    padding: 2px;

  }
  .labelInputContainer>label{
    margin-bottom: 2px;
  }
  .search-input {
    padding: 0 6px;
  }
  #hr {
    position: absolute;
    width: 40px;
    top: 0.2rem;
    left: -1rem;
    padding: 0.6px;
    background-color: #07344829;

    -webkit-transform: rotate(90deg);
  }
  .nav-search-btn,
  .nav-search-btn:hover,
  .nav-search-btn:focus,
  .nav-search-btn:active {
    position: absolute;
    top: -0.4rem;
    right: -0.5rem;
    border: none;
    background-color: #073448;
    color: #ffffff;
    width: 112%;
    height: 112%;
    font-size: 12px;
  }
  .search-row h6 {
    font-size: 10px;
  }
  .search-row p {
    font-size: 10px;
  }

  .bac-sec h1 {
    font-size: 15px;
  }
  .bac-sec-faq h1 {
    font-size: 15px;
  }
  /* .logo{
       
        width: 15%;
    } */
  /* .search-row{
    
        position:absolute;
        top: -1rem;
        left: 2rem;
        background-color: #ffffff;
        padding: 1% 1% 0% 1%;
    
      width: 90%;
        border-radius: 20px 1px 1px 20px;
  
    
    } */
  .logo {
    position: absolute;
    top: 2rem;
    margin: auto !important;
   
    display: block;
    width: 15%;
  }
  .navbar a {
    font-size: 80%;
  }
}

@media (max-width: 700px) {
  .bac-sec h1 {
    font-size: 15px;
  }
  #hr {
    position: absolute;
    width: 40px;
    top: 0.2rem;
    left: -1rem;
    padding: 0.6px;
    background-color: #07344829;

    -webkit-transform: rotate(90deg);
  }
  .nav-search-btn,
  .nav-search-btn:hover,
  .nav-search-btn:focus,
  .nav-search-btn:active {
    position: absolute;
    top: -0.3rem;
    right: -0.5rem;
    border: none;
    background-color: #073448;
    color: #ffffff;
    width: 110%;
    height: 110%;
    font-size: 10px;
  }
  /* .search-row{
    
        position:absolute;
        top: -.7rem;
        left: 2rem;
        background-color: #ffffff;
        padding: 1% 1% 0% 1%;
    
      width: 90%;
        border-radius: 20px 1px 1px 20px;
  
    
    } */
  .search-row h6 {
    font-size: 9px;
  }
  .search-row p {
    font-size: 9px;
  }
  .logo {
    position: absolute;
    top: 2rem;
    margin: auto !important;
    display: block;
    width: 15%;
  }
  .navbar a {
    font-size: 80%;
  }
}
@media (max-width: 576px) {
  .logo {
    position: absolute;
    top: 2rem;
    margin: auto !important;
    display: block;
    width: 15%;
  }
  .navbar a {
    font-size: 80%;
  }
}
@media (max-width: 479px) {
  .nav-search-btn,
  .nav-search-btn:hover,
  .nav-search-btn:focus,
  .nav-search-btn:active {
    position: absolute;
    top: -0.3rem;
    right: -0.5rem;
    border: none;
    background-color: #073448;
    color: #ffffff;
    width: 110%;
    height: 107%;
    font-size: 10px;
  }
  .search-row {
    position: absolute;
    top: -1rem;
    left: 2rem;
    background-color: #ffffff;
    padding: 1% 1% 0% 1%;

    width: 90%;
    border-radius: 20px 1px 1px 20px;
  }
  .search-row h6 {
    font-size: 9px;
  }
  .search-row p {
    font-size: 9px;
  }
}
@media (max-width: 430px) {
  .nav-search-btn,
  .nav-search-btn:hover,
  .nav-search-btn:focus,
  .nav-search-btn:active {
    position: absolute;
    top: -0.3rem;
    right: -0.5rem;
    border: none;
    background-color: #073448;
    color: #ffffff;
    width: 110%;
    height: 107%;
    font-size: 10px;
  }
  .search-row {
    position: absolute;
    top: -1.5rem;
    left: 2rem;
    background-color: #ffffff;
    padding: 1% 1% 0% 1%;

    width: 89%;
    border-radius: 20px 1px 1px 20px;
  }
  .search-row h6 {
    font-size: 8px;
  }
  .search-row p {
    font-size: 8px;
  }
}
@media (max-width: 1000px) {
  .nav-search-container {
    margin-bottom: 70px;
  }
  .navbar a {
    font-size: 14px;
  }
  .logo {
    top: 1.5rem;
    margin: auto !important;

    width: 90px;
  }
}

.hide-row{
  display: block;
}
@media (max-width: 768px) {
  .search-mr{
margin-top: 50px;
  }
  
  .bac-sec h1 {
    font-size: 17px;
  }
  .nav-search-container {
    margin-bottom: 30px;
  }
 
 
 
  .navbar a {
    font-size: 13px;
  }
  .logo {
    top: 2.2rem;
    margin: auto !important;
    width: 100px;
  }
  .bac-sec {
    margin-left: 0px !important;
    height: 250px;
    
  }
  .bac-sec h1 {
    font-size: 22px;
  }
}
@media (max-width: 600px) {
  .hide-row{
    display: none;
  }
  .hide-row{
    display: none;
  }
  .nav-search-container {
    margin-bottom: 10px;
  }
  
  .logo {
    top: 0.5rem;
    margin: auto !important;

    width: 130px;
  }
  .navbar a {
    font-size: 12px;
  }
  .bac-sec {
    margin-left: 0px !important;
    height: 200px;
    padding-right: 40%;
  }
  .bac-sec h1 {
    font-size: 22px;
  }
}
@media (max-width: 500px) {
  .labelInputContainer{
    padding: 2px;

  }
  .labelInputContainer>label{
    margin-bottom: 2px;
  }
  .search-input {
    padding: 0;
  }

  .hide-row{
    display: none;
  }
  
  .logo {
    top: 1rem;
    margin: auto !important;

    width: 120px;
  }
  .navbar a {
    font-size: 12px;
  }
  .bac-sec {
    height: 200px;
    padding-right: 40%;
    margin-left: 0px !important;
  }
}
@media (max-width: 400px) {


.logo{
  top:1.3rem !important;
  width:90px !important;
  margin: auto !important;
  
 
}


  .bac-sec {
    background-image: url("../../../images/shutterstock_245455543.jpg");
    background-repeat: no-repeat;
    background-size:cover;
    height: 450px !important;
    background-position:-450px 10px;
    margin-left: 0px !important;
    width: 100% !important;
    
    font-size: 100%;
    color: #073448;
    font-family: "lexSemi2";
    font-weight: bolder;
    margin-top: -100px !important;
  }

  .chalets-header{
    
    top:-120px;
    font-size: 15px !important;
   
  }

  
  .hide-row{
    display: none;
  }
  
  .bac-sec {
    height: 135px;
    padding-right: 40%;
    margin-left: 0px !important;
  }
  .bac-sec h1 {
    
    margin-top: 25px;
  }
  .navbar a {
    font-size: 11px;
  }
  .logo {
    top: 2.2rem;
    margin: auto !important;

    width: 50px;
  }
  .nav-search-btn,
  .nav-search-btn:hover,
  .nav-search-btn:focus,
  .nav-search-btn:active {
    position: absolute;
    top: -0.3rem;
    right: -0.5rem;
    border: none;
    background-color: #073448;
    color: #ffffff;
    width: 110%;
    height: 107%;
    font-size: 10px;
  }
  /* .search-row{
    
        position:absolute;
        top: 0rem;
        left: 2rem;
        background-color: #ffffff;
        padding: 1% 1% 0% 1%;
    
      width: 89%;
        border-radius: 20px 1px 1px 20px;
  
    
    } */
  .search-row h6 {
    font-size: 8px;
  }
  .search-row p {
    font-size: 8px;
  }
}
@media (max-width: 350px) {
  .hide-row{
    display: none;
  }
  .nav-search-btn,
  .nav-search-btn:hover,
  .nav-search-btn:focus,
  .nav-search-btn:active {
    position: absolute;
    top: -0.3rem;
    right: -0.5rem;
    border: none;
    background-color: #073448;
    color: #ffffff;
    width: 110%;
    height: 107%;
    font-size: 10px;
  }
  /* .search-row{
    
        position:absolute;
        top: 0rem;
        left: 2rem;
        background-color: #ffffff;
        padding: 1% 1% 0% 1%;
    
      width: 89%;
        border-radius: 20px 1px 1px 20px;
  
    
    } */
  .search-row h6 {
    font-size: 8px;
  }
  .search-row p {
    font-size: 8px;
  }
}




@media (min-width: 700px) and (max-width: 1000px) {
 

 
.search-dest-width{
  width:180px !important;
}
#hr{
  left:20 !important;
  top:15px !important;
  width: 75px;
}
  .nav-search-btn{
    height: 114%;
  }

  

  


}
input[type="text"]:focus-visible {
  outline:none !important;
}
