.card-ed {

 
  border: none;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  padding-right:0px;
 
}

.no-padding{
  padding-right: 7px !important;
  padding-left: 0px !important;
  
}

.no-padding2{
  padding-right: 0px !important;
  padding-left: 0px !important;
  
}
.card-ed img {
  object-fit:cover;
  object-position: center;
  background-repeat: no-repeat;
  height: 210px;
  
}
.colored-text {
  color: #073448;
}

.card-text {
  font-size: 15px;
}
@media (max-width: 1300px) {
  .card-ed img {
   
    height: 190px;
  }
  .card-ed {
    padding-right: 5%;
  }
}
@media ((min-width: 900px) and (max-width:1220px)) {
  .card-ed img {
   
    height: 190px;
  }
}
@media (max-width: 900px) {
  .card-ed img {
   
    height: 190px;
  }
  .card-ed {
    padding-right: 5%;
  }
}
@media (max-width: 700px) {
  .card-ed img {
   
    height: 190px;
  }
  
}

@media (max-width:468px){

  .img-link{
    display: flex;
    justify-content: center;
    align-items: center;
  
  }

  .card-ed img {
    width: 100% !important;
}

 s

  

  .cards-container{
    padding-right: 0px !important;
  }
  .card-text-ctr{
    padding-right: 0px !important;
  }

  .card-stays-ctr{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width:180px;
    margin-left: -17px !important;
  }

  .stays-mobile>p{
    font-size: 13px;
   margin-left: 3px;
   width: 100%;
  }

  .stays-avg{
    width: 33%;
   }

   .avg-mobile>p{
    font-size: 13px !important;
   }

   
   .stays-price{
    width: 33%;
   }
 



.swiper-button-prev{
  top:100px !important;
  /* margin-left: -10px!important; */
}

.swiper-button-next{
  top:98px !important;
  /* margin-left:-45px !important; */
}

 
  .card-body{
    margin-left: 0px !important;
  }
}
