.bac-sec-faq {
  background-image: url("../../../../images/shutterstock_460364623.jpg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: top;
  height: 200px;
  width: 100%;
 margin: 0 !important;
  font-size: 100%;
  color: #073448;
}
.qu-body{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 200px 0px;
}

.FAQ-txt-ctr{
  padding-top: 100px;
}
.bac-sec-faq h1 {
  font-family: "lexSemi3";
  padding-top: 1rem;
  padding-bottom: 5px;
}
.navbar-mobile-faq {
  position: absolute;
  overflow: hidden;
  top: 0%;
  right: 0% !important;
  box-shadow: 6px 6px 30px 10px rgb(0, 0, 0, 0.4);
  bottom: 0;
  background-color: #f5efef;
  transition: 0.3s;
  z-index: 999;
  width: 300px;
  height: 200px;
  padding: 2rem;
  border-radius: 4px;
}
.search-box,
.search-box:focus,
.search-box:active,
.search-box::after,
.search-box::before,
.search-box:hover,
.search-box:visited {
  border: none;
  padding: 20px 0px 30px 0px;
  color: #073448;
  width: 100%;
}
.qu-Card {
  cursor: pointer;
  color: #073448;
  border: 1px solid #07344899;
  border-radius: 5px;
  text-align: center;
  padding: 30px 0px 30px 0px;
  margin-bottom: 15%;
}
.qu-Card-active {
  cursor: pointer;
  color: #ffffff;
  border: 1px solid #07344899;
  border-radius: 5px;
  text-align: center;
  padding: 30px 0px 30px 0px;
  margin-bottom: 15%;
  background-color: #073448;
}

.qu-op-Card {
  cursor: pointer;
  color: #073448;
  border: 1px groove #073448ae;
  border-radius: 10px;
  margin-bottom: 2%;
  padding: 20px 0px 20px 30px;
  font-size: 14px;
  width: 100%;
}
.nav-search-btn-min-faq {
  display: none;
}
.qu-op-Card-active {
  cursor: pointer;
  color: #ffffff;
  background-color: #073448;
  border: 1px groove #073448;
  border-radius: 10px;
  margin-bottom: 2%;
  padding: 20px 0px 20px 30px;
  font-size: 14px;
  width: 100%;
}

@media (max-width: 2560px) {
  .bac-sec-faq {
    height: 540px;
  }
  
  
  .bac-sec-faq h1 {
    font-size: 45px;
  }
}
@media (max-width: 2000px) {
  .bac-sec-faq {
    height: 650px;
   
  }

  .bac-sec-faq h1 {
    font-size: 50px;
  }
  
  .bac-sec-faq p {
    font-size: 25px;
  }
}
@media (max-width: 1900px) {
  .bac-sec-faq {
    height: 650px;
   
  }
  
  
  .bac-sec-faq h1 {
    font-size: 50px;
  }
  .bac-sec-faq p {
    font-size: 22px;
  }
}
@media (max-width: 1800px) {
  .bac-sec-faq {
    height: 600px;
   
  }
 
  
  .bac-sec-faq h1 {
    font-size: 50px;
  }
  .bac-sec-faq p {
    font-size: 22px;
  }
}
@media (max-width: 1700px) {
  .bac-sec-faq {
    height: 540px;
    
  }
 
  .bac-sec-faq h1 {
    font-size: 45px;
  }
  .bac-sec-faq p {
    font-size: 20px;
  }
}
@media (max-width: 1600px) {
  .bac-sec-faq {
    height: 540px;
    
  }
 



  .bac-sec-faq h1 {
    font-size: 42px;
  }
  .bac-sec-faq p {
    font-size: 19px;
  }
}
@media (max-width: 1400px) {
  .bac-sec-faq {
    height: 500px;
   
  }
 
  .bac-sec-faq h1 {
    font-size: 40px;
  }
  .bac-sec-faq p {
    font-size: 18px;
  }
}
@media (max-width: 1300px) {
  .bac-sec-faq {
    height: 450px;
   
  }

  
  
  .bac-sec-faq h1 {
    font-size: 35px;
  }
  .bac-sec-faq p {
    font-size: 15px;
  }
}
@media (max-width: 1200px) {
  .bac-sec-faq {
    height: 400px;
    
  }

  

  .bac-sec-faq h1 {
    font-size: 25px;
  }
  
  .bac-sec-faq p {
    font-size: 15px;
  }
}

@media (max-width: 1000px) {
  .bac-sec-faq h1 {
    font-size: 22px;
  }

 
  .bac-sec-faq {
    height: 340px;
   
  }
 
  .bac-sec-faq p {
    font-size: 13px;
  }
}


@media (max-width: 767px) {
  .bac-sec-faq h1 {
    font-size: 15px;
  }

 
 
  
}



@media (max-width: 700px) {
  .bac-sec-faq h1 {
    font-size: 15px;
  }
  .search-row{
    max-width:80% !important;
    border-radius: 20px;
  }
 
}
@media(max-width:900px)
{
  .bac-sec-faq h1 {
    font-size: 20px;
  }
  .bac-sec-faq {
    height: 300px;
   
  }
  .bac-sec-faq p {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .nav-search-btn-min-faq {
    display: block;
    margin-top: 8%;
    border: none;
    border-radius: 4px;
    background-color: #073448;
    color: #ffffff;
    font-size: 15px;
    padding: 3% 70%;
  }
  .bac-sec-faq h1 {
    font-size: 17px;
  }

  .bac-sec-faq {
    height: 250px;
    
  }
  .bac-sec-faq h1 {
    font-size: 22px;
  }
}
@media (max-width: 600px) {
  .nav-search-btn-min-faq {
    display: block;
    margin-top: 7%;
    border: none;
    border-radius: 4px;
    background-color: #073448;
    color: #ffffff;
    font-size: 15px;
    padding: 0% 70%;
  }

  
  .bac-sec-faq {
    height: 200px;
   
  }
  .bac-sec-faq h1 {
    font-size: 22px;
  }
  .bac-sec-faq p {
    font-size: 11px;
  }
}
@media (max-width: 600px) {

  .bac-sec-faq {
    background-image: url("../../../../images/shutterstock_460364623.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    height: 400px !important;
    width: 100%;
   
    font-size: 100%;
    color: #073448;
    margin: 0 !important;
  }
 .FAQ-txt-ctr>h1{
  font-size: 18px !important;
 }

  .FAQ-txt{
    font-size: 16px !important;
    width:300px !important;
   
   
  }

  .FAQ-txt>h1{
    font-size: 21px !important;
  }


  .nav-search-btn-min-faq {
    display: block;
    margin-top: 140px !important;
    border: none;
    height:40px !important;
    border-radius: 4px;
    background-color: #073448;
    color: #ffffff;
    font-size: 15px;
    padding: 0% 52%;
  }
.qu-op-Card{
  margin-left: 15px;
}

.qu-Card {
  height:50px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qu-Card-active{
  height:50px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

  .bac-sec-faq {
    
    height: 200px;
   
  }
  .bac-sec-faq p {
    font-size: 11px;
  }
  .bac-sec-faq h1{
    padding-top: 0;
  }
}
@media (max-width: 400px) {
  .nav-search-btn{
    
    width:50px;
    height:50px;
    position: relative;
    left:-100%;
    top:10px;
  }
  .bac-sec-faq {
    height: 130px;
    
  }
  .bac-sec-faq h1 {
    font-size: 13px;
  }
  .bac-sec-faq p {
    font-size: 10px;
  }
  .qu-body {
    margin-top: 5rem;
  }

  .nav-search-btn{
    border-radius: 50%;
    width:50px;
    height:50px;
  }
}


@media (max-width: 400px) {

  .nav-search-btn, .nav-search-btn:hover, .nav-search-btn:focus, .nav-search-btn:active {
      position: absolute !important;
      top: 0 !important; 
      right: 0 !important;
      border: none !important;
      background-color: #073448 !important;
      color: #ffffff !important;
      width: 100% !important;
      height: 100% !important;
      font-size: 12px !important;
      left: 0% !important;
      border-top-right-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
  }
  .search-box, .search-box:focus, .search-box:active, .search-box::after, .search-box::before, .search-box:hover, .search-box:visited {
    border: none !important;
    padding: 20px 0px 20px 0px !important;
    color: #073448 !important;
    width: 100% !important;
    text-overflow: ellipsis !important;
}
}
@media (max-width: 430px) {

  .search-row {
      position: absolute !important;
      top: -1.8rem !important;
      left: auto !important;
      background-color: #ffffff !important;
      padding: 0 !important;
      width: 90% !important;
      /*border-radius: 20px 1px 1px 20px;
      */max-width: 90% !important;
      border-radius: 20px !important;
      -webkit-border-radius: 20px !important;
      -moz-border-radius: 20px !important;
      -ms-border-radius: 20px !important;
      -o-border-radius: 20px !important;
}
}