.bac-sec-add {
  background-image: url("../../../../images/shutterstock_1571243716.jpg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: top;
  height: 500px;
  width: 100%;
  margin: 0 !important;
  font-size: 100%;
  color: #ffffff;
}

.text1{
  width:100%;
  display: block;
 margin: auto;
  padding: 0px 600px 0px 15px;
  
}


.text2{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}


.PhoneInputInput {
  flex: 1 1;
  min-width: 0;
  border: none;
}
.add-mr{
  margin-bottom: 2rem;
}

.form-container {
  background-color: #ffffff;
  top: -24rem;
  right: 10rem;
  border-radius: 5px;
  box-shadow: 2px 2px 15px 0px #0734485c;
  width: 390px;
}
.submit-button {
  border-radius: 5px;
  border: none;
  color: #ffffff;
  background-color: #073448;
  text-align: center;
  width: 100%;
  height: 38px;
  font-size: 13px;
}
.input-field {
  border-radius: 5px;
  border: 1px solid #0734487b;
  width: 100%;
  padding: 5px 0px 5px 12px;
  color: #073448;
  font-family: "lexlight";
}
.form-body {
  padding-bottom: 200px;
}
.text-sec {
  padding-right: 55%;
}



@media (min-width:1550px){
  .text1{
    margin-left: 80px;
  }

 


}


@media(min-width:1500px)
{

  .form-container{
    top:-22rem;
  }

  

}


@media(max-width:1400px)
{

  .bac-sec-add {height: 500px;
 }

}
@media(max-width:1300px)
{
  .text1{
    width:100%;
    display: block;
   margin: auto;
    padding: 0px 600px 0px 15px !important;
    
  }

  .text-sec {
    padding-right: 55%;
  }
  .add-mr{
    margin-bottom: 8rem !important;
  }
  .bac-sec-add h1{
    font-size: 30px;
  }
  .bac-sec-add p{
    font-size: 16px;
  }
  .bac-sec-add {height: 300px;
 }
 
  .form-container{
    top: -14rem;
    left: 40rem;
    width: 390px;
  }
}
@media(max-width:1200px)
{

  .text-sec {
    padding-right: 55%;
  }
  .add-mr{
    margin-bottom: 9rem !important;
  }
  .bac-sec-add h1{
    font-size: 25px;
  }
  .bac-sec-add p{
    font-size: 16px;
  }
  .bac-sec-add {height: 300px;
}
  .form-container{
    top: -14rem;
    left: 35rem;
    width: 390px;
  }
}
@media(min-width:1300px){
  .text1{
    width:100%;
    display: block;
   margin: auto;
    padding: 0px 600px 0px 15px !important;
    
  }
}
@media(max-width:991px)
{

  .text-sec {
    padding-right: 45%;
  }

  .text1{
    width:100%;
    display: block;
   margin: auto;
    padding: 0px 15px 0px 15px !important;
    
  }

  .add-mr{
    margin-bottom: 12rem !important;
  }
  .bac-sec-add h1{
    font-size: 20px;
  }
  .bac-sec-add p{
    font-size: 15px;
  }
  .bac-sec-add {height: 300px;
  }
  .form-container{
    top: -10rem;
    left: 24rem;
    width: 350px;
  }
}
@media(max-width:768px)
{

  .text-sec {
    padding-right: 0%;
  }
  .add-mr{
    margin-bottom: 40rem !important;
  }
  .bac-sec-add h1{
    font-size: 17px;
  }
  .bac-sec-add p{
    font-size: 12px;
  }
  .bac-sec-add {height: 200px;
}
  .form-container{
    top: 10rem;
    /* left: 4rem; */
    left: 50%;
    transform: translateX(-50%);
    width: 400px;
  }
}
@media(max-width:600px)
{

  .text2{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .text1{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  .text-sec {
    padding-right: 0%;
  }
  .add-mr{
    margin-bottom: 45rem !important;
  }
  .bac-sec-add h1{
    font-size: 17px;
  }
  .bac-sec-add p{
    font-size: 12px;
  }
  .bac-sec-add {height: 200px;
 }

 
  .form-container{
    top: 10rem;
    /* left: 3rem; */
    left: 50%;
    transform: translateX(-50%);
    width: 400px;
  }
}
@media(max-width:500px)
{

  .text-sec {
    padding-right: 0%;
  }
  .add-mr{
    margin-bottom: 45rem !important;
  }
  .bac-sec-add h1{
    font-size: 17px;
  }
  .bac-sec-add p{
    font-size: 12px;
  }
  .bac-sec-add {
    height: 150px;
 }
  .form-container{
    top: 15rem;
    /* left: 4rem; */
    left: 50%;
    transform: translateX(-50%);
    width: 350px;
  }
}
@media(max-width:450px)
{
  .form-container{
    top: 15rem;
    /* left: 2rem; */
    left: 50%;
    transform: translateX(-50%);
    width: 350px;
  }
}
@media(max-width:400px)
{

  

  .bac-sec-add {
    background-image: url("../../../../images/shutterstock_1571243716.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 300px !important;
    width: 100%;
   
    font-size: 100%;
    color: #ffffff;
  }
.text1>p>h1{
  font-size: 26px;
}

.text1>p{
  font-size: 13px !important;
}
  .text-sec {
    padding-right: 0%;
    
  }
  
  .add-mr{
    margin-bottom: 45rem !important;
  }
  .bac-sec-add h1{
    font-size: 15px;
  }
  .bac-sec-add p{
    font-size: 10px;
  }
  .bac-sec-add {height: 150px;
  }
  .form-container{
    top: 15rem;
    /* left: 8vw; */
    left: 50%;
    transform: translateX(-50%);
    width: 300px;
  }
}